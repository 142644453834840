<template>
  <div>
    <div v-if="$acl.check('school')" class="vx-card p-6">
      <div class="w-full">
        <h5 class="my-2">Solicitudes de Cobro - Pago Directo</h5>
      </div>
      <div class="flex flex-wrap items-center">

        <!-- ITEMS PER PAGE -->
        <div class="flex-grow">
          <vs-dropdown vs-trigger-click class="cursor-pointer">
            <div class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
              <span class="mr-2">{{ currentPage * paginationPageSize - (paginationPageSize - 1) }} - {{ pendingPayments.length - currentPage * paginationPageSize > 0 ? currentPage * paginationPageSize : pendingPayments.length }} de {{ pendingPayments.length }}</span>
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
            </div>
            <!-- <vs-button class="btn-drop" type="line" color="primary" icon-pack="feather" icon="icon-chevron-down"></vs-button> -->
            <vs-dropdown-menu>
              <vs-dropdown-item @click="gridApi.paginationSetPageSize(20)">
                <span>20</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="gridApi.paginationSetPageSize(50)">
                <span>50</span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
        </div>

        <!-- TABLE ACTION COL-2: SEARCH & EXPORT AS CSV -->
          <vs-input class="sm:mr-4 mr-0 sm:w-auto w-full sm:order-normal order-3 sm:mt-0 mt-4" v-model="searchQuery" @input="updateSearchQuery" placeholder="Buscar..." />
          <vs-button class="mb-4 md:mb-0" @click="gridApi.exportDataAsCsv()">Exportar</vs-button>
      </div>


      <!-- AgGrid Table -->
      <!-- :components="components" -->
      <ag-grid-vue
        ref="agGridTable"
        :gridOptions="gridOptions"
        class="ag-theme-material w-100 my-4 ag-grid-table"
        :columnDefs="columnDefs"
        :defaultColDef="defaultColDef"
        :rowData="pendingPayments"
        rowSelection="multiple"
        colResizeDefault="shift"
        :animateRows="true"
        :pagination="true"
        :paginationPageSize="paginationPageSize"
        :suppressPaginationPanel="true"
        :enableRtl="$vs.rtl"
        :modules="modules">
      </ag-grid-vue>

      <vs-pagination
        :total="totalPages"
        :max="7"
        v-model="currentPage" />

      <popup-detail
          :data="popupData"
          :title="popupTitle"
          :popupActive="popupActive"
          @updatePopupActive="updatePopupActive(false)"
      ></popup-detail>
    </div>
  </div>
</template>
<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { AgGridVue } from "@ag-grid-community/vue";
import {AllCommunityModules} from '@ag-grid-community/all-modules';
import "@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss";
import CellRendererActionsPendingPayments from "./components/CellRendererActionsPendingPayments.vue";
// Store Modules
import PopupDetail from "./components/PaymentRequestDetails.vue"
import moduleDirectPayment from "@/store/direct-payment/moduleDirectPayment.js"
import moduleDataListInstitutions from "@/store/data-list-institutions/moduleDataList.js"
import moduleUserManagement from "@/store/user-management/moduleUserManagement.js"

export default {
    components: {
        VuePerfectScrollbar,
        AgGridVue,
        CellRendererActionsPendingPayments,
        PopupDetail
    },
    data() {
        return {
            modules: AllCommunityModules,
            pendingPayments: [],

            //Sidebar
            paymentsSidebar: false,
            paymentsSidebarActive: false,
            sidebarData: {},

            // Popup Details
            popupData: [],
            popupTitle: 'Detalle de Pagos Directos recibidos',
            popupActive: false,

            //MockData
            mockData: [
                {
                    "id": "",
                    "school_name": "School Test",
                    "school_admin": "Admin School 123",
                    "period": "November 2023",
                    "request_date": "",
                    "total": 12300,
                    "processing_fee_percent": 1,
                    "tiptap_amount_commission": 123,
                    "national_tax_percent": 7,
                    "national_tax_amount": 8.61,
                    "total_pay": 12168.39,
                    "test": "test"
                },
                {
                    "id": "1",
                    "school_name": "School Test",
                    "school_admin": "Admin School 123",
                    "period": "November 2023",
                    "request_date": "15/11/2023",
                    "total": 5000,
                    "processing_fee_percent": 1,
                    "tiptap_amount_commission": 50,
                    "national_tax_percent": 7,
                    "national_tax_amount": 3.50,
                    "total_pay": 4946.50
                }
            ],

            //Table
            searchQuery: "",
            gridOptions: {
              alignedGrids: [],
              suppressHorizontalScroll: true,
              context: {}
            },
            maxPageNumbers: 7,
            gridApi: null,
            defaultColDef: {
                sortable: true,
                editable: false,
                resizable: true,
                suppressMenu: true,
            },

            groupHeaderHeight: 150,
            headerHeight: 100,

            columnDefs: [
                // {
                //     headerName: "Ticket",
                //     field: "id",
                //     width: 60,
                //     filter: true,
                // },
                {
                    headerName: "Escuela",
                    field: "school_name",
                    width: 200,
                    filter: true
                },
                {
                    headerName: "Total Pagos Recibidos",
                    field: "total_before_tax",
                    width: 150,
                    filter: true,
                    valueFormatter: params => this.$options.filters.currencySign(params.data.total_before_tax, '$ '),
                },
                {
                    headerName: "Comisión",
                    field: "tiptap_commission_amount",
                    width: 180,
                    filter: true,
                    valueFormatter: params => `${this.$options.filters.currencySign(params.data.tiptap_commission_amount, '$ ')} ${params.data.tiptap_commission_percent != undefined ? '(' + this.$options.filters.currencyPercentage(params.data.tiptap_commission_percent, ' %') + ')' : ''}`,
                    // cellStyle: {color: 'blue', fontWeight: '600'}
                },
                {
                    headerName: "ITBMS",
                    field: "national_tax_amount",
                    width: 180,
                    filter: true,
                    valueFormatter: params => `${this.$options.filters.currencySign(params.data.national_tax_amount, '$ ')} ${params.data.national_tax_percent != undefined ? '(' + this.$options.filters.currencyPercentage(params.data.national_tax_percent, ' %') + ')' : ''}`
                },
                {
                    headerName: "Total a Cobrar",
                    field: "total_after_tax",
                    width: 150,
                    filter: true,
                    valueFormatter: params => this.$options.filters.currencySign(params.data.total_after_tax, '$ '),
                },
                // {
                //     headerName: "Estado",
                //     field: "request_date",
                //     width: 140,
                //     filter: true,
                //     comparator: this.myDateComparator,
                //     valueFormatter: params => this.resolveStatus(params.data.request_date),
                // },
                {
                    headerName: "Acción",
                    filter: true,
                    width: 140,
                    cellRendererFramework: 'CellRendererActionsPendingPayments',
                    cellRendererParams: {
                        openSideBar: this.openSideBar.bind(this),
                        showPopup: this.showPopup.bind(this),
                    }
                },
            ],
        }
    },
    created() {
      this.$store.dispatch(`dataListInstitutions/getSchools`)
        .then(() => {
            this.loadPendingPaymentsTables()
        })
        .catch(err => console.log(err))
    },
    methods: {
        isDate(val) {
            return val instanceof Date && !isNaN(val.valueOf())
        },

        loadPendingPaymentsTables() {
            // this.pendingPayments = this.mockData
            this.pendingPayments = []

            this.$store.dispatch("directPaymentModule/getDirectPaymentPendingPayments")
                .then(response => {
                    if (response.data && response.data.payments && response.data.payments.length > 0) {
                      let s = JSON.parse(JSON.stringify(response.data));
                      s.school_name = this.$store.getters['dataListInstitutions/getSchoolById'](this.$store.state.AppActiveUser.institution.id).name

                      this.pendingPayments = [s];
                    }
                })
                .catch(err => console.log(err))
        },
        getQueryParams() {
          return `status=0`
        },
        toggleDataSidebar(val=false) {
          this.paymentsSidebarActive = val
        },
        openSideBar() {
            this.toggleDataSidebar(true);
        },
        updateSearchQuery(val) {
            this.gridApi.setQuickFilter(val);
        },
        myDateComparator(date1, date2) {
            var date1Number = this.monthToComparableNumber(date1);
            var date2Number = this.monthToComparableNumber(date2);
            if (date1Number === null && date2Number === null) {
                return 0;
            }
            if (date1Number === null) {
                return -1;
            }
            if (date2Number === null) {
                return 1;
            }
            return date1Number - date2Number;
        },
        monthToComparableNumber(date) {
            if (date === undefined || date === null || date.length !== 10) {
                return null;
            }
            var yearNumber = date.substring(6, 10);
            var monthNumber = date.substring(3, 5);
            var dayNumber = date.substring(0, 2);
            var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
            return result;
        },
        resolvePeriod(period) {
            if(!period) return
            const p = new Date(period)
            return p.toLocaleString('default', { month: 'long' }) + ' ' + p.getFullYear()
        },
        showPopup(data) {
          // this.getRequestDetail(requestData.id)
          this.popupData = data.payments
          this.popupActive = true
        },
        updatePopupActive(val) {
          this.popupActive = val
        },
        getRequestDetail(id) {
          if (!id) return

          this.$store.dispatch("reportModule/getRecurringServicesRequestDetail", id)
          .then(response => {
            if (!response.status) return

            response.data.requests.detail.forEach(detail => {
              detail.student_display_name = detail.student_name + ' ' + detail.student_last_name
              detail.guardian_display_name = detail.guardian_name + ' ' + detail.guardian_last_name
              detail.due_date = new Date(detail.expiration_date).toLocaleDateString("es-AR", this.$localeDateOptions)
              detail.expiration_date = this.getPeriodByExpirationDate(detail.expiration_date)
            })

            this.popupData = response.data.requests.detail
            this.popupTitle = `Detalle de solicitud #${id}`
            this.popupActive = true
          })
          .catch(error => console.log(error))
        },
        getPeriodByExpirationDate(expirationDate) {
          return this.$store.getters['reportModule/getPeriodTextByExpirationDate'](expirationDate)
        },
        onFirstDataRendered: function() {
          this.gridColumnApi.autoSizeAllColumns();
        },
        resolveStatus(val) {
          if(val && val != "")
            return `Solicitado el ${val}`

          return 'Pendiente'
        }
    },
    watch: {
        paymentsSidebarActive: function () {
            this.toggleDataSidebar(this.paymentsSidebarActive)
        },
        "$store.state.windowWidth"(val) {
            if (val <= 576) {
                this.maxPageNumbers = 7;
            }
        },
    },
    computed: {
      paginationPageSize() {
      if (this.gridApi) return this.gridApi.paginationGetPageSize();
        else return 50;
      },
      totalPages() {
        if (this.gridApi) return this.gridApi.paginationGetTotalPages();
        else return 0;
      },
      currentPage: {
        get() {
          if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
          else return 1;
        },
        set(val) {
          this.gridApi.paginationGoToPage(val - 1);
        },
      },
    },
    mounted() {
      this.gridApi = this.gridOptions.api;
      this.gridColumnApi = this.gridOptions.columnApi;

      if (this.$vs.rtl) {
        const header = this.$refs.agGridTable.$el.querySelector(
          ".ag-header-container"
      );
      header.style.left =
          "-" + String(Number(header.style.transform.slice(11, -3)) + 9) + "px";
      }
    },
    beforeMount() {
      this.gridOptions.context = {
        componentParent: this
      };
    },
    beforeCreate() {
      if(!moduleDirectPayment.isRegistered) {
        this.$store.registerModule('directPaymentModule', moduleDirectPayment)
        moduleDirectPayment.isRegistered = true
      }
      if(!moduleDataListInstitutions.isRegistered) {
        this.$store.registerModule('dataListInstitutions', moduleDataListInstitutions)
        moduleDataListInstitutions.isRegistered = true
      }
      if(!moduleUserManagement.isRegistered) {
        this.$store.registerModule('userManagement', moduleUserManagement)
        moduleUserManagement.isRegistered = true
      }
    },
}
</script>
<style>
  .ag-header-cell-label .ag-header-cell-text{
    overflow: visible;
    text-overflow: clip;
    white-space: normal;
  }
  .ag-grid-table.total .ag-row {
    font-weight: 700;
  }
  .ag-grid-table.total .ag-header {
    border: none;
  }
</style>
